/* UpcomingProjects.module.css */

/* Container styles */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.heading {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.projectsContainer {
  display: flex;
  flex-direction: column;
}

.project {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
  animation: fadeIn 1s ease-in-out;
}

.leftProject {
  flex-direction: row;
}

.rightProject {
  flex-direction: row-reverse;
}

.imageContainer {
  flex: 1;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.projectImage {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
  border-radius: 8px;
}

.projectImage:hover {
  transform: scale(1.05);
}

.projectDetails {
  flex: 1;
  padding: 2rem;
}

.projectTitle {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.projectDescription {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.learnMoreButton {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 0.8rem 1.6rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
}

.learnMoreButton:hover {
  background-color: #555;
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
}

.modalContent {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  max-width: 90%;
  width: 100%;
  overflow-y: auto; /* Add overflow-y property to enable vertical scrolling */
}

.scrollableContent {
  max-height: 400px; /* Set the maximum height for the content */
}

.modalContent h2 {
  margin-top: 0;
  font-size: 2rem;
}

.modalContent p {
  margin-bottom: 20px;
  font-size: 1.2rem;
}

.modalContent button {
  padding: 0.8rem 1.6rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
  margin-bottom: 2rem;
}

.modalContent button:hover {
  background-color: #0056b3;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.modalContent h2 {
  margin-top: 0;
  font-size: 2rem;
}

.modalContent p {
  margin-bottom: 20px;
  font-size: 1.2rem;
}

.modalContent button {
  padding: 0.8rem 1.6rem;
  background-color: navy;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
}

.modalContent button:hover {
  background-color: #0056b3;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .project {
    flex-direction: column;
  }

  .leftProject,
  .rightProject {
    flex-direction: column;
  }

  .imageContainer {
    margin-bottom: 2rem;
  }
}
