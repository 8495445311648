/* Default styles */
.navbar {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
  
  .logo  {
    font-weight: bold;
    padding-bottom: 8px;
  }
  
  .navLinks {
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: auto; /* Right-align links */
  }
  .poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600 !important;
    font-style: normal;
  }
  .navLink {
    text-decoration: none;
    color: navy;
  }
  
  .ctaButton {
    background-color: navy;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 20px; /* Modern shape */

    cursor: pointer;
    margin-left: 1rem; /* Add spacing between CTA and links */
    transition: all 0.3s ease; /* Smooth transition */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow */
  }
  
  .ctaButton:hover {
    background-color: #001f3f; /* Darker color on hover */
  }
  
  .ctaButton:active {
    transform: translateY(2px); /* Push button down on click */
  }
  
  /* Responsive styles */
  @media (max-width: 767px) {
    .navbar {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .navLinks {
      margin-top: 1rem;
      margin-left: 0; /* Reset margin for mobile */
    }
  
    .ctaButton {
      margin-left: 0; /* Reset margin for mobile */
      margin-top: 1rem; /* Add spacing between CTA and links for mobile */
    }
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .navbar {
      padding: 1.5rem;
    }
  }
  
  @media (min-width: 1024px) {
    .navbar {
      padding: 0.5rem 2rem;
    }
  }
  