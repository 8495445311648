.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .modalContent {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 2rem;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px;
    position: relative;
  }
  
  .closeButton {
    color: #aaa;
    float: right;
    font-size: 2rem;
    font-weight: bold;
    cursor: pointer;
  }
  
  .closeButton:hover,
  .closeButton:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  
  .modalTitle {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  
  .modalImage {
    width: 100%;
    height: 300px;
    object-fit: cover;
    margin-bottom: 1rem;
  }
  
  .modalDetails {
    line-height: 1.6;
  }