.footer {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' id='visual' viewBox='0 0 1200 540' width='1200' height='540' version='1.1'%3E%3Crect x='0' y='0' width='1200' height='540' fill='%23000000'%3E%3C/rect%3E%3Cg fill='none' stroke='%23297EA6' stroke-width='4'%3E%3Cpath d='M0 -122.7L27.5 -37.9L116.7 -37.9L44.6 14.5L72.1 99.3L0 46.9L-72.1 99.3L-44.6 14.5L-116.7 -37.9L-27.5 -37.9Z' transform='translate(233 303)'/%3E%3Cpath d='M0 -53.8L12.1 -16.6L51.2 -16.6L19.5 6.4L31.6 43.5L0 20.5L-31.6 43.5L-19.5 6.4L-51.2 -16.6L-12.1 -16.6Z' transform='translate(546 508)'/%3E%3Cpath d='M0 -72.7L16.3 -22.5L69.1 -22.5L26.4 8.6L42.7 58.8L0 27.8L-42.7 58.8L-26.4 8.6L-69.1 -22.5L-16.3 -22.5Z' transform='translate(756 9)'/%3E%3Cpath d='M0 -98.2L22 -30.3L93.4 -30.3L35.7 11.6L57.7 79.4L0 37.5L-57.7 79.4L-35.7 11.6L-93.4 -30.3L-22 -30.3Z' transform='translate(412 61)'/%3E%3C/g%3E%3C/svg%3E") repeat;
  /* Adjust background properties as needed */
  background-size: cover;
  background-position: center;    color: #fff;
    padding: 40px 0;
  }
  
  .footerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .logo {
    font-size: 24px;
  }
  
  .contactInfo {
    margin-bottom: 20px;
  }
  
  .address,
  .phone {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .address i,
  .phone i {
    margin-right: 5px;
  }
  
  .links ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  
  .links ul li {
    display: inline-block;
    margin-right: 20px;
  }
  
  .links ul li:last-child {
    margin-right: 0;
  }
  
  .links ul li a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .links ul li a:hover {
    color: #f0f0f0;
  }
  
  .socialMedia {
    font-size: 24px;
  }
  
  .socialMedia a {
    color: #fff;
    text-decoration: none;
    margin-right: 15px;
    transition: color 0.3s;
  }
  
  .socialMedia a:hover {
    color: #f0f0f0;
  }
  
  .copyRight {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
  }
  
  /* Media query for small screens */
  @media only screen and (max-width: 768px) {
    .footerContainer {
      flex-direction: column;
      align-items: center;
    }
  
    .contactInfo,
    .links,
    .socialMedia {
      margin-bottom: 20px;
    }
  
    .links ul li {
      display: block;
      margin: 10px 0;
    }
  
    .socialMedia a {
      margin-right: 10px;
    }
  }
  a{
    color: #fff;
    text-decoration: none;
  }