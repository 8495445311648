/* Services.module.css */
.servicesContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  background-color: #f8f8f8;
}

.heading {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2.5rem;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.services {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}

.service {
  text-align: center;
  padding: 2rem;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.5s ease-in-out forwards;
}

.service:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border: 2px dashed #020202;
  border-radius: 30%;
  position: relative;
  overflow: hidden;
  transition-delay: 1s;
}


.service:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent, #000000, transparent);
  animation: lightning 10s ease infinite;
}


@keyframes lightning {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.iconAnimation {
  animation: iconBounce 2s infinite;
}

.serviceTitle {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.serviceDescription {
  font-size: 1rem;
  color: #666;
}

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes iconBounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .services {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .services {
    grid-template-columns: 1fr;
  }
}