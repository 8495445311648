/* BubblesBackground.module.css */

.bubblesContainer {
    position: relative;
}

.bubbles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;

    height: 100%;
}

  .bubble {
    position: absolute;
    background-color: #b3e0ff; /* Bubble color */
    border-radius: 50%;
    animation: float 5s infinite alternate ease-in-out;
  }
  
  @keyframes float {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100vh);
    }
  }
  