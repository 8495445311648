:root {
  --main-color-orange: #e77a1b;
  --main-color-blue: #264653;
  --main-color-green: #4CA131;
}

.serviceBox {
  color: var(--main-color);
  font-family: 'Roboto', sans-serif;
  text-align: center;
  padding: 0 30px 30px;
  position: relative;
  z-index: 1;
}

.serviceBox:before,
.serviceBox:after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  top: 38px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  z-index: -1;
}

.serviceBox:after {
  background-color: var(--main-color);
  border-radius: 30px;
  top: 55%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -2;
}

.serviceBox .service-icon {
  color: #fff;
  background: var(--main-color);
  font-size: 35px;
  line-height: 67px;
  width: 75px;
  height: 75px;
  margin: 0 auto 60px;
  border-radius: 50%;
  border: 5px solid #fff;
  position: relative;
}

.serviceBox .service-icon:before,
.serviceBox .service-icon:after {
  content: '';
  background-color: var(--main-color);
  height: 70px;
  width: 140px;
  border-radius: 0 0 100px 100px/0 0 100px 100px;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: -1;
}

.serviceBox .service-icon:before {
  height: 10px;
  width: 170px;
  border-radius: 10px 10px 0 0;
  top: auto;
  bottom: 50%;
}

.serviceBox .title {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  text-shadow: 0 4px rgba(0,0,0,0.05);
  margin: 0 0 10px;
}

.serviceBox .description {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
    line-height: 1.6;
    color: #555;
  margin: 0;
}

.serviceBox.orange { --main-color: var(--main-color-orange); }
.serviceBox.blue { --main-color: var(--main-color-blue); }
.serviceBox.green { --main-color: var(--main-color-green); }
