.container {
  position: relative;
}

.container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(circle, #2d709c 10%, transparent 10%);
  background-size: 20px 20px;
  z-index: -1;
  animation: animateWaves 10s linear infinite;
}

@keyframes animateWaves {
  0% {
    background-position: 0 0;
  }
  100% {
    transform: translateY(0) rotate(0deg);
  }
}

.whySection {
    display: grid;
    grid-template-columns: auto 1fr 1fr 1fr;
    gap: 2rem;
    padding: 2rem;
    overflow-x: auto; /* Add horizontal overflow auto to prevent overlapping */
  }
  
  .heading {
    grid-column: 1 / -1;
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2rem;
    margin-bottom: 2rem;
    position: relative;
    display: inline-block;
    padding-bottom: 1rem;
    color: #050505;
  }
  
  .column {
    display: flex;
    align-items: center;
  }
  
  .image {
    width: 100%;
    max-width: 220px;
    height: auto;
    margin-right: 1rem;
  }
  @media (max-width: 550) {
    .image {
      max-width: 300px; /* Decrease image width */
    }
  }
  
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .box:hover {
    transform: translateY(-5px);
  }
  
  .icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .icon:hover {
    transform: scale(1.05);
  }
  
  .description {
    text-align: center;
  }
  
  .title {
    color: navy;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .subtitle {
    color: #555;
    font-size: 1rem;
    line-height: 1.4;
  }
  
  @media (max-width: 1024px) {
    .whySection {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Change to auto-fit columns */
    }
  }
  