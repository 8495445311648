.whyChoose {
    /* background-color: #f0f0f0; */
    background-image: url('../../assets/images/whyChoose.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 80px 0;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .title {
    text-align: center;
    margin-bottom: 40px;
    color: #f3f0f0;
  }
  
  .cardWrapper {
    position: relative;
  }
  

  
  .cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 30px;
  }
  
  .card {
    width: calc(33.3333% - 30px);
    padding: 30px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    overflow: hidden;
    position: relative;
  }
  
  .card::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    pointer-events: none;
    z-index: -1;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .icon {
    width: 50px;
    height: 50px;
    background-color: #ff6f61;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  
  .subtitle {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
  }
  
  p {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
  }
  
  @media (max-width: 768px) {
    .card {
      width: calc(50% - 30px);
    }
  }
  
  @media (max-width: 576px) {
    .card {
      width: 100%;
    }
  }
  /* BubblesBackground.module.css */

.bubblesContainer {
  position: relative;
  width: 100%;
  height: 100%; /* Adjust as needed */
  overflow: hidden;
}

.bubble {
  position: absolute;
  background-color: #b3e0ff; /* Bubble color */
  border-radius: 50%;
  animation: float 5s infinite alternate ease-in-out;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100vh);
  }
}
