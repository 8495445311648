.featureContainer{
background-image: url('../../assets/images/whyChoose.svg');
}


.feature {
    padding: 6rem 0;
    margin: 0rem auto;
   
    max-width: 1000px; /* Increase maximum width */
    text-align: center;
    overflow: hidden;

  }
  .heading {
    font-size: 2rem;
    margin-bottom: 2rem;
    position: relative;
    display: inline-block;
    padding-bottom: 1rem;
    color: #fbfbfb;
  }
  
  .heading::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 150%;
    height: 2px;
    background-color: #333;
    transform: translateX(-50%);
  }
  .card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;  margin: 0 1rem; /* Add margin to create space between cards */

  }
  
  .image {
    width: 100%;
    height: auto;
  }
  
  .details {
    padding: 1rem;
    background-color: #fbfbfb;
  }
  
  .name {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .location {
    font-size: 1rem;
    color: #666;
  }
  .bubblesContainer {
    position: relative;
    width: 100%;
    height: 100%; /* Adjust as needed */
    overflow: hidden;
  }
  
  .bubble {
    position: absolute;
    background-color: #b3e0ff; /* Bubble color */
    border-radius: 50%;
    animation: float 5s infinite alternate ease-in-out;
  }
  
  @keyframes float {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100vh);
    }
  }
  