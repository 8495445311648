.heroSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    background-color: #f5f5f5;
    font-family: "Poppins", sans-serif;
  font-weight: 600 !important;
  font-style: normal;
  }
  
  .heroContent {
    flex: 1;
    padding-right: 2rem;
  }
  
  .heading {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .button {
    padding: 0.75rem 1.5rem;
    background-color: navy;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .heroImage {
    flex: 1;
  }
  @media (max-width: 768px) {
    .heroSection {
      flex-direction: column;
      text-align: center;
    }
  
    .heroContent {
      padding: 0;
    }
  
    .heroImage {
      margin-top: 2rem;
    }
  }
  
  .heroImage img {
    max-width: 100%;
    height: auto;
  }