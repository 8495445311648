.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .heading {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  
  .searchContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }
  
  .searchInput {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 300px;
  }
  
  .projectsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .project {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .project:hover {
    transform: translateY(-8px);
  }
  
  .projectImage {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .projectName {
    font-size: 1.2rem;
    margin: 1rem 0;
  }
  
  .projectDescription {
    color: #666;
  }