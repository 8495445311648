.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 4rem 2rem;
  }
  
  .heading {
    text-align: center;
    font-size: 3rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 4rem;
  }
  
  .content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
    gap: 2rem;
  }
  @media (max-width: 768px) {
    .content {
      grid-template-columns: 1fr;
    }
  }
  
  .card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    transition: transform 0.3s ease;
    position: relative;
  }
  
  .card::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid transparent;
    border-radius: 8px;
    transition: border-color 0.3s ease;
  }
  
  .card:hover::after {
    border-color: #007bff; /* Change this to the desired border color */
  }
  
  .card:hover {
    transform: translateY(-8px);
  }
  
  
  .cardHeader {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .cardHeading {
    font-size: 1.8rem;
    font-weight: 600;
    color: #333;
    margin-right: 1rem;
  }
  
  .headerLine {
    flex-grow: 1;
    height: 2px;
    background-color: #333;
  }
  
  .cardText {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
  }