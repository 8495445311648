.hero {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../assets//images/2151264503.jpg');
    background-size: cover;
    background-position: center;
    color: white;
  }
  
  .content {
    width: 400px; /* Set width to 400px */
    margin-left: 4rem;
    padding: 2rem;
    text-align: left; /* Left-align text */
    background-color: rgba(255, 255, 255, 0.2); /* Transparent white background */
    border-radius: 10px; /* Rounded corners */
    backdrop-filter: blur(5px); /* Apply blur effect to the background */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
  }
  
  .title {
    font-size: 2rem; /* Reduce font size */
    margin-bottom: 1rem;
  }
  
  .description {
    font-size: 1rem; /* Reduce font size */
    margin-bottom: 1.5rem;
    color: #fbfbfb;
  }
  .brand{
    font-size: 1.5rem;
    font-family: "Poppins", sans-serif;
  font-weight: 500 ;
  font-style: normal;
    margin-bottom: 1.5rem;
    color: navy;
  }
  .buttons {
    display: flex;
    justify-content: center;
    gap: 1rem; /* Add spacing between buttons */
  }
  
  .callUsButton, .learnMoreButton {
    border: 2px solid #fff;
    padding: 0.5rem 1.5rem; /* Adjust padding */
    border-radius: 5px; /* Rounded corners */
    background-color: transparent;
    color: rgb(238, 236, 236);
    font-size: 0.9rem; /* Reduce font size */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
  }
  
  .callUsButton:hover, .learnMoreButton:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  
  .learnMoreButton {
    background-color: transparent;
    border: 2px solid #fff;
  }
  
  @media (min-width: 768px) {
    .content {
      width: 100%; /* Allow box to expand to full width on larger screens */
      max-width: 400px; /* Set maximum width */
    }
  
    .buttons {
      justify-content: flex-start;
    }
  }
  