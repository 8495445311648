.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .title {
    text-align: center;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
  }
  
  .col {
    flex: 0 0 calc(50% - 15px);
    max-width: calc(50% - 15px);
    margin-bottom: 30px;
  }
  
  @media (max-width: 768px) {
    .col {
      flex: 0 0 calc(100% - 15px);
      max-width: calc(100% - 15px);
    }
  }
  