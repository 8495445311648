/* App.module.css */
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #000;
    
   background-image:
   /* Large bubbles */
   radial-gradient(circle at 10% 20%, #715DF2 35px, transparent 36px),
   radial-gradient(circle at 80% 60%, #715DF2 40px, transparent 41px),
   radial-gradient(circle at 40% 80%, #715DF2 38px, transparent 39px),

   /* Medium bubbles */
   radial-gradient(circle at 20% 40%, #715DF2 28px, transparent 29px),
   radial-gradient(circle at 60% 20%, #715DF2 30px, transparent 31px),
   radial-gradient(circle at 90% 70%, #715DF2 32px, transparent 33px),

   /* Small bubbles */
   radial-gradient(circle at 30% 10%, #715DF2 20px, transparent 21px),
   radial-gradient(circle at 70% 50%, #715DF2 22px, transparent 23px),
   radial-gradient(circle at 50% 90%, #715DF2 18px, transparent 19px);
  background-repeat: no-repeat;
      animation: bubbleAnimation 10s linear infinite;

  }
  
  .questionContainer {
    background-color: #1a3b5d;
    color: #ffffff;
    padding: 2rem;
    border-radius: 0.5rem;
    text-align: center;
    max-width: 600px;
    width: 100%;
  }
  
  .questionHeading {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .questionText {
    margin-bottom: 2rem;
    color: #fbfbfb;

  }
  
  .formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .input {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    border: none;
  }
  .textarea {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    border: none;
  }
  .submitButton {
    background-color: navy;
    color: #ffffff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  
  /* Responsive styles */
  @media (max-width: 480px) {
    .questionContainer {
      padding: 1rem;
    }
  
    .questionHeading {
      font-size: 1.2rem;
    }
  }
