.ourTeam {
    overflow: hidden;
    border-radius: 35%;
    position: relative;
    max-width: 500px;
  }
  
  .ourTeam img {
    width: 100%;
    height: auto;
    position: relative;
    right: 0;
    transition: all 0.4s ease-in-out 0s;
  }
  
  .ourTeam:hover img {
    right: 60%;
  }
  
  .teamContent {
    width: 65%;
    height: 100%;
    background: #f1f1f1;
    padding: 20% 30px 0;
    position: absolute;
    top: 0;
    right: -80%;
    transition: all 0.4s ease-in-out 0s;
  }
  
  .ourTeam:hover .teamContent {
    right: 0;
  }
  
  .title {
    font-size: 22px;
    color: #8e0c80;
    margin: 0 0 10px 0;
  }
  
  .post {
    display: block;
    font-size: 12px;
    font-weight: 900;
    color: #333;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  
  .description {
    font-size: 14px;
    color: #333;
  }
  
  .social {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .social li {
    display: inline-block;
    margin-right: 10px;
  }
  
  .social li:last-child {
    margin-right: 0;
  }
  
  .social a {
    display: block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    color: #999;
    border-radius: 4px;
    text-align: center;
    transition: all 0.3s ease-in-out 0s;
  }
  
  .social a:hover {
    color: #fff;
  }
  
  .faFacebook:hover {
    background: #3b5a9b;
  }
  
  .faTwitter:hover {
    background: #2baae1;
  }
  
  .faGooglePlus:hover {
    background: #f63d26;
  }
  
  .faYoutube:hover {
    background: #c3181e;
  }
  
  @media only screen and (max-width: 990px) {
    .ourTeam {
      margin-bottom: 50px;
    }
  }
  
  @media only screen and (max-width: 360px) {
    .teamContent {
      padding: 15% 15px 0;
    }
  
    .social li {
      margin-right: 0;
    }
  }
  