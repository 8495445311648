.container {
  position: relative; overflow: hidden;
  background-image: url('../../assets/images/whyChoose.svg');
  background-position: right bottom;
}

/* .container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(-45deg, transparent 33.33%, #ffffff 33.33%, #ffffff 66.67%, transparent 66.67%),
    linear-gradient(45deg, transparent 33.33%, #ffffff 33.33%, #ffffff 66.67%, transparent 66.67%);
  background-size: 20px 20px;
  z-index: -1;
  animation: animateLines 10s linear infinite;
} */

@keyframes animateLines {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 20px 20px;
  }
}


.services {
    text-align: center;
    padding: 2rem;
  }
  
  .header {
    font-size: 2rem;
    margin-bottom: 2rem;
    position: relative;
    display: inline-block;
    padding-bottom: 1rem;
  }
  
  .header::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 150%;
    height: 2px;
    background-color: #333;
    transform: translateX(-50%);
  }
  
  .serviceContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .service {
    width: 300px;
    margin: 1rem;
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #D9AFD9;
    background-image: linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%);
        text-align: center;
  }
  
  .header {
    font-size: 2rem;
    margin-bottom: 2rem;
    position: relative;
    display: inline-block;
    padding-bottom: 1rem;
    color: #fbfbfb;
  }
  
  .description {
    font-size: 1rem;
  }
  .bubblesContainer {
    position: relative;
    width: 100%;
    height: 100%; /* Adjust as needed */
    overflow: hidden;
  }
  
  .bubble {
    position: absolute;
    background-color: #b3e0ff; /* Bubble color */
    border-radius: 50%;
    animation: float 5s infinite alternate ease-in-out;
  }
  
  @keyframes float {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100vh);
    }
  }